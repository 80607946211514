<template>
  <div class="pt-4">
    <v-sheet class="mb-4">
      <div class="mb-2 d-flex">
        <v-checkbox
          v-model="showExactAmount"
          hide-details
          label="顯示精確金額"
          class="mr-4"
        >
        </v-checkbox>

        <v-checkbox
          v-model="showChart"
          hide-details
          label="顯示圖表"
        >
        </v-checkbox>
      </div>

      <div class="mb-2">
        <v-chip
          label
          small
          class="white--text mr-2 mb-2"
          :color="$helper.getAccountingRecordTypeColorClass('cost')">
          開銷: {{getAmount(costTotal)}}
        </v-chip>

        <v-chip
          label
          small
          class="white--text mr-2 mb-2"
          :color="$helper.getAccountingRecordTypeColorClass('income')">
          收入: {{getAmount(incomeTotal)}}
        </v-chip>

        <v-chip
          label
          small
          class="white--text mr-2 mb-2"
          :color="$helper.getAccountingRecordTypeColorClass('tax')">
          稅務: {{getAmount(taxTotal)}}
        </v-chip>
      </div>

      <div>
        <v-chip
          v-for="type in costTypes" 
          :key="type"
          label
          small
          class="white--text mr-2 mb-2 blue"
        >
          {{$t(`cost.type.${type}`)}}: {{getAmount(costBreakdown[type])}}
        </v-chip>
      </div>

      <div class="my-4" v-if="showChart">
        <costChart
          :costBreakdown="costBreakdown"
          :costTotal="costTotal"
        ></costChart>
      </div>
    </v-sheet>
    <v-sheet height="64">
      <v-toolbar
        flat
      >
        <v-btn
          fab
          text
          small
          color="grey darken-2"
          @click="prevMonth()"
        >
          <v-icon small>
            fa-chevron-left
          </v-icon>
        </v-btn>
        
        <v-spacer></v-spacer>

        <v-menu
          ref="menu"
          v-model="monthPickerStatus"
          :close-on-content-click="false"
          :return-value.sync="monthPickerValue"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
              class="text-h5"
            >
              {{ yearMonthLabel }}
            </div>
          </template>
          <v-date-picker
            v-model="monthPickerValue"
            type="month"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="monthPickerStatus = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="pickMonth"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>

        <v-spacer></v-spacer>

        <v-btn
          fab
          text
          small
          color="grey darken-2"
          @click="nextMonth()"
        >
          <v-icon small>
            fa-chevron-right
          </v-icon>
        </v-btn>
      </v-toolbar>
    </v-sheet>
    
    <v-sheet
      v-resize="initSize"
      :style="{height: `${calendarHeight}px`}"
    >
      <v-calendar
        ref="calendar"
        class="accounting-calendar"
        :weekdays="[1, 2, 3, 4, 5, 6, 0]"
        type="month"
        v-model="calendarDate"
      >
        <template v-slot:day="{date}">
          <dateColumn
            :yearMonth="yearMonth"
            :date="date"
            :reloadRecords="loadRecords"
          ></dateColumn>
        </template>
      </v-calendar>
    </v-sheet>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import accountingConstants from 'modules/accounting/config/accountingConstants'
export default {
  metaInfo() {
    return {
      title: this.$t('page.calendar'),
    }
  },
  data: () => ({
    calendarHeight: 0,
    calendarDate: null,
    loadRecordDelay: null,
    monthPickerStatus: false,
    monthPickerValue: null,
    showExactAmount: false,
    showChart: false,
  }),
  beforeDestroy() {
    this.$store.commit('accounting/resetRecords')
  },
  mounted() {
    this.initSize()
    this.calendarDate = this.routeMonth ?? this.$helper.now('YYYY-MM-01')
    this.$store.dispatch('loading/progress')
    this.$root.$on('loadRecords', () => this.loadRecords())
  },
  methods: {
    getAmount(amount) {
      if (this.showExactAmount) {
        return amount
      }
      return this.$helper.amountChinese(amount)
    },
    pickMonth() {
      this.setMonth()
      this.monthPickerStatus = false
    },
    async setMonth() {
      this.calendarDate = dayjs(this.monthPickerValue).format('YYYY-MM-01')
      await this.$nextTick()
      this.$router.push({
        query: {
          month: dayjs(this.calendarDate).format('YYYY-MM'),
        },
      })
    },
    async initSize() {
      const scale = this.$helper.isMobile() ? 2 : 1.5
      this.calendarHeight = $(this.$el).width()/7*5*scale
    },
    async setToday() {
      this.calendarDate = this.$helper.now('YYYY-MM-01')
      await this.$nextTick()
      this.$router.push({
        query: {},
      })
    },
    async prevMonth() {
      this.$refs.calendar.prev()
      await this.$nextTick()
      this.$router.push({
        query: {
          month: dayjs(this.calendarDate).format('YYYY-MM'),
        },
      })
    },
    async nextMonth() {
      this.$refs.calendar.next()
      await this.$nextTick()
      this.$router.push({
        query: {
          month: dayjs(this.calendarDate).format('YYYY-MM'),
        },
      })
    },
    async loadRecords() {
      this.$store.dispatch('loading/progress')
      const start = this.dateOfMonthStart
      const end = this.dateOfMonthEnd
      try {
        const result = await this.$api.collection.accountingApi.records({
          start_at: start,
          end_at: end,
        })
        this.$store.commit('accounting/setRecords', result)
      } catch(error) {
        console.error(error)
      } finally {
        this.$store.dispatch('loading/closeProgress')
      }
    },
  },
  computed: {
    routeMonth() {
      const month = this.$route.query.month
      let result = null
      try {
        result = dayjs(month).format('YYYY-MM-01')
      } catch (e) {
        return result
      }
      return result
    },
    yearMonth() {
      if (!this.calendarDate) return ''
      return dayjs(this.calendarDate).format('YYYY-MM')
    },
    yearMonthLabel() {
      if (!this.calendarDate) return ''
      return dayjs(this.calendarDate).format('YYYY年MM月')
    },
    dateOfMonthStart() {
      if (!this.calendarDate) return ''
      return dayjs(this.calendarDate).startOf('month').format('YYYY-MM-DD')
    },
    dateOfMonthEnd() {
      if (!this.calendarDate) return ''
      return dayjs(this.calendarDate).endOf('month').format('YYYY-MM-DD')
    },
    incomeTotal() {
      return this.$store.getters['accounting/incomeTotal']
    },
    taxTotal() {
      return this.$store.getters['accounting/taxTotal']
    },
    costTotal() {
      return this.$store.getters['accounting/costTotal']
    },
    costBreakdown() {
      return this.$store.getters['accounting/costBreakdown']
    },
    costTypes() {
      return accountingConstants.COST_TYPES
    }
  },
  components: {
    costChart: () => import('@/modules/accounting/partials/costChart.vue'),
    dateColumn: () => import('@/modules/accounting/partials/calendar/dateColumn.vue'),
  },
  watch: {
    calendarDate() {
      if (this.loadRecordDelay) {
        window.clearTimeout(this.loadRecordDelay)
      }
      this.loadRecordDelay = window.setTimeout(() => {
        this.loadRecords()
      }, 500)
    },
  },
}
</script>

<style lang="sass" type="text/sass">
.accounting-calendar
  .v-calendar-weekly__day-label
    cursor: default
    button
      pointer-events: none
    .theme--light.v-btn.v-btn--has-bg
      background-color: #b3b3b3
</style>
